import React, { useState, useEffect } from "react";
import axios from "axios";
import ProductsList from "./ProductsList";
import { Link, useNavigate } from "react-router-dom";

import fsAnimated from "../images/fs-animado.png";
import msAnimated from "../images/ms-animado.png";
import axeAnimated from "../images/hacha-animado.png";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faChevronDown,
  faChevronRight,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

const ApiURL = "https://www.caceresmaquinarias.com.ar/api";

const scrollToTop = () => {
  window.scrollTo({
    top: 260,
    behavior: "smooth",
  });
};

const buildUrl = (name) =>
  `/productos/${encodeURIComponent(name.toLowerCase().replace(/\s+/g, "-"))}`;

const FiltersProducts = () => {
  const navigate = useNavigate();

  const [categories, setCategories] = useState([]);
  const [brands, setBrands] = useState([]);
  const [categoryFilter, setCategoryFilter] = useState([]);
  const [brandFilter, setBrandFilter] = useState([]);
  const [brandsExpanded, setBrandsExpanded] = useState(true);
  const [categoriesExpanded, setCategoriesExpanded] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [products, setProducts] = useState([]);
  const [brandsVisible, setBrandsVisible] = useState(false);
  const [categoriesVisible, setCategoriesVisible] = useState(false);

  useEffect(() => {
    const fetchBrands = async () => {
      try {
        const response = await axios.get(`${ApiURL}/marcas`);
        setBrands(response.data);
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };

    fetchBrands();
  }, []);

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const response = await axios.get(`${ApiURL}/categorias`);
        setCategories(response.data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };

    fetchCategories();
  }, []);

  const toggleFilter = async (filterId, setFilter) => {
    await setFilter((prevFilters) =>
      prevFilters.includes(filterId)
        ? prevFilters.filter((id) => id !== filterId)
        : [...prevFilters, filterId]
    );
  };

  useEffect(() => {
    setBrandsVisible(false);
    setCategoriesVisible(false);
  }, []);

  useEffect(() => {
    const hasFilters = categoryFilter.length > 0 || brandFilter.length > 0;

    const brandSlugs = brandFilter.map((brandId) => {
      const brand = brands.find((b) => b._id === brandId);
      return brand ? brand.name.toLowerCase().replace(/\s+/g, "-") : "";
    });

    const categorySlugs = categoryFilter.map((categoryId) => {
      const category = categories.find((c) => c._id === categoryId);
      return category ? category.name.toLowerCase().replace(/\s+/g, "-") : "";
    });

    const updatedBrandFilter =
      brandSlugs.length > 0 ? `${brandSlugs.join(",")}` : "";

    const updatedCategoryFilter =
      categorySlugs.length > 0 ? `${categorySlugs.join(",")}` : "";

    const queryParams = [updatedCategoryFilter, updatedBrandFilter]
      .filter((param) => param !== "")
      .join(",");

    const url = hasFilters ? `/productos/${queryParams}` : "/productos";

    navigate(url);
  }, [categoryFilter, brandFilter, navigate, categories, brands]);

  const handleSearchInputChange = async (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    try {
      const searchTerms = encodeURIComponent(newSearchTerm.toLowerCase());
      const response = await axios.get(
        `${ApiURL}/productos?search=${searchTerms}`
      );
      setCategoryFilter([]);
      setBrandFilter([]);
      setProducts(response.data);
    } catch (error) {
      console.error("Error al buscar productos:", error);
    }
  };

  const handleClearSearch = () => {
    setSearchTerm("");
  };

  return (
    <div className="main-container-products">
      <div className="animated-header">
        <img
          src={fsAnimated}
          alt="FS animated STIHL"
          className="animated-imgs fs-animated"
        />
        <img src={msAnimated} alt="MS animated STIHL" className="ms-animated" />
        <img
          src={axeAnimated}
          alt="AXE animated STIHL"
          className="animated-imgs axe-animated"
        />
        <div className="search-bar-container">
          <div className="search_bar">
            <div className="search_box">
              <input
                type="text"
                className="input-bar"
                placeholder="Buscar productos, marcas y más..."
                value={searchTerm}
                onChange={handleSearchInputChange}
              />
              <div className="search-cleaner" onClick={handleClearSearch}>
                <FontAwesomeIcon icon={faXmark} className="fa-xmark-bar" />
              </div>
              <div className="btn btn_common">
                <FontAwesomeIcon
                  icon={faSearch}
                  className="search-icon fa-search"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="wrap-products">
        <div className="filter-button-container">
          <button
            className="filter-button"
            onClick={() => {
              setBrandsVisible(!brandsVisible);
              setCategoriesVisible(!categoriesVisible);
            }}
          >
            Filtrar por marcas o categorías
            <FontAwesomeIcon
              icon={brandsVisible ? faChevronDown : faChevronRight}
              className="arrow-button"
            />
          </button>
        </div>
        <div className="filters-products-container">
          <div className="filters-container">
            <div
              className={`brands-column categories-brands-list ${
                brandsVisible ? "visible" : "hidden"
              }`}
            >
              <h2
                className={`brands-title filter-title ${
                  brandsExpanded ? "expanded" : "collapsed"
                }`}
                onClick={() => setBrandsExpanded(!brandsExpanded)}
              >
                Filtrar por marcas
                <FontAwesomeIcon
                  icon={brandsExpanded ? faChevronDown : faChevronRight}
                  className="filter-title-icon"
                />
              </h2>
              {brandsExpanded && (
                <ul>
                  {brands.map((brand) => (
                    <li key={brand._id}>
                      <Link
                        to={buildUrl(brand.name)}
                        onClick={(event) => {
                          event.preventDefault();
                          toggleFilter(brand._id, setBrandFilter);
                          scrollToTop();
                        }}
                        className={
                          brandFilter.includes(brand._id) ? "active" : ""
                        }
                      >
                        {brand.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <div
              className={`categories-brands-list ${
                categoriesVisible ? "visible" : "hidden"
              }`}
            >
              <h2
                className={`filter-title ${
                  categoriesExpanded ? "expanded" : "collapsed"
                }`}
                onClick={() => setCategoriesExpanded(!categoriesExpanded)}
              >
                Filtrar por categorías
                <FontAwesomeIcon
                  icon={categoriesExpanded ? faChevronDown : faChevronRight}
                  className="filter-title-icon"
                />
              </h2>
              {categoriesExpanded && (
                <ul className="columns">
                  {categories.map((category) => (
                    <li key={category._id}>
                      <Link
                        to={buildUrl(category.name)}
                        onClick={(event) => {
                          event.preventDefault();
                          toggleFilter(category._id, setCategoryFilter);
                          if (!categoryFilter.includes(category._id)) {
                            scrollToTop();
                          }
                        }}
                        className={
                          categoryFilter.includes(category._id) ? "active" : ""
                        }
                      >
                        {category.name}
                      </Link>
                    </li>
                  ))}
                </ul>
              )}
            </div>
            {brandsVisible || categoriesVisible ? (
              <div
                className="overlay-list"
                onClick={() => {
                  setBrandsVisible(false);
                  setCategoriesVisible(false);
                }}
              ></div>
            ) : null}
          </div>
          <div className="products-grid">
            <ProductsList
              categoryFilter={categoryFilter}
              brandFilter={brandFilter}
              searchTerm={searchTerm}
              products={products}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default FiltersProducts;
