import React, { useEffect } from "react";
import "../styles/main.css";

import { Link, useLocation } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import {
  faFacebook,
  faInstagram,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import "@fortawesome/fontawesome-free/css/all.css";
import logo from "../images/Logo-CM.png";

const Navbar = ({ isContactSection }) => {
  const location = useLocation();
  const isHome = location.pathname === "/";

  const scrollToContact = (event) => {
    event.preventDefault();
    const contactSection = document.getElementById("contacto");

    if (contactSection) {
      contactSection.scrollIntoView({ behavior: "smooth" });
    }
  };

  useEffect(() => {
    const mainMenu = document.querySelector(".mainMenu");
    const closeMenu = document.querySelector(".closeMenu");
    const openMenu = document.querySelector(".openMenu");
    const menuItems = document.querySelectorAll("nav .mainMenu li a");

    const show = () => {
      mainMenu.style.display = "flex";
      mainMenu.style.top = "0";
    };

    const close = () => {
      mainMenu.style.top = "-115%";
    };

    openMenu.addEventListener("click", show);
    closeMenu.addEventListener("click", close);

    menuItems.forEach((item) => {
      item.addEventListener("click", close);
    });

    return () => {
      openMenu.removeEventListener("click", show);
      closeMenu.removeEventListener("click", close);
      menuItems.forEach((item) => {
        item.removeEventListener("click", close);
      });
    };
  }, []);

  return (
    <>
      <nav className={isHome ? "homeNavbar" : "otherNavbar"}>
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="openMenu">
          <FontAwesomeIcon icon={faBars} />
        </div>
        <ul className="mainMenu">
          <li>
            <Link to="/">Inicio</Link>
          </li>
          <li>
            <Link to="/productos">Productos</Link>
          </li>
          <li>
            <a href="/contacto" onClick={scrollToContact}>
              Contacto
            </a>
          </li>
          <div className="closeMenu">
            <FontAwesomeIcon icon={faTimes} />
          </div>
          <span className="icons">
            <a
              href="https://www.facebook.com/caceresmaq"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} />
            </a>
            <a
              href="https://www.instagram.com/caceresmaquinarias/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faInstagram} />
            </a>
            <a
              href="https://wa.me/+5493464500623"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faWhatsapp} />
            </a>
          </span>
        </ul>
      </nav>
    </>
  );
};

export default Navbar;
