import React, { useRef, useEffect } from "react";
import "../styles/main.css";
import stihl from "../images/logos/stihl.png";
import tromen from "../images/logos/tromen.png";
import bosch from "../images/logos/bosch.png";
import briggs from "../images/logos/briggs-stratton.png";
import skil from "../images/logos/skil.png";
import bta from "../images/logos/bta.png";
import clorotec from "../images/logos/clorotec.png";
import dewalt from "../images/logos/dewalt.png";
import einhell from "../images/logos/einhell.png";
import fiasa from "../images/logos/fiasa.png";
import intraud from "../images/logos/intraud.png";
import mtd from "../images/logos/mtd.png";
import niwa from "../images/logos/niwa.png";
import pampa from "../images/logos/ppro.png";
import shimura from "../images/logos/shimura.png";
import tauro from "../images/logos/tauro.png";

const Logos = () => {
  const sliderRef = useRef(null);

  useEffect(() => {
    const slider = sliderRef.current;
    const sliderCopy = slider.cloneNode(true);
    slider.parentNode.appendChild(sliderCopy);
  }, []);

  return (
    <div className="logos">
      <div className="logos-slide" ref={sliderRef}>
        <img src={bosch} alt="Logo Bosch" className="logo-bosch" />
        <img src={briggs} alt="Logo Briggs & Stratton" />
        <img src={skil} alt="Logo Skil" />
        <img src={bta} alt="Logo BTA" />
        <img src={clorotec} alt="Logo Clorotec" />
        <img src={stihl} alt="Logo Stihl" />
        <img src={tromen} alt="Logo Tromen" />
        <img src={dewalt} alt="Logo Dewalt" />
        <img src={einhell} alt="Logo Einhell" />
        <img src={fiasa} alt="Logo Fiasa" />
        <img src={intraud} alt="Logo Intraud" />
        <img src={mtd} alt="Logo MTD" />
        <img src={niwa} alt="Logo Niwa" />
        <img src={pampa} alt="Logo Pampa" />
        <img src={shimura} alt="Logo Shimura" />
        <img src={tauro} alt="Logo Tauro" />
      </div>
    </div>
  );
};

export default Logos;
