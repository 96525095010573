import React from "react";
import "../styles/main.css";
import "bootstrap/dist/css/bootstrap.min.css";
import imageBg from "../images/stihl-wp.jpg";

const Background = () => {
  return (
    <div className="container-img">
      <img src={imageBg} alt="STIHL" className="bg-img" />
      <div className="container-text">
        <h2>
          Concesionario oficial <br />
          Stihl
        </h2>
        <p>Encontrá todo lo que necesitás para tu hogar</p>
      </div>
    </div>
  );
};

export default Background;
