import React, { useState, useEffect } from "react";
import data from "../utils/ContentFaq";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMinus,
  faChevronDown,
  faMobileScreen,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebook,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import "font-awesome/css/font-awesome.min.css";

const SingleQuestion = ({ id, title, info, expanded, onQuestionClick }) => {
  const handleClick = () => {
    onQuestionClick(id);
  };

  return (
    <article className={`question ${expanded ? "expanded" : ""}`}>
      <header className="question-header">
        <h4 onClick={handleClick}>{title}</h4>
        <button className="btn" onClick={handleClick}>
          {expanded ? (
            <FontAwesomeIcon icon={faMinus} size="1x" />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} size="1x" />
          )}
        </button>
      </header>
      <div className={`question-container ${expanded ? "expanded" : ""}`}>
        {expanded && <p className="answer-text">{info}</p>}
      </div>
    </article>
  );
};

const Accordion = () => {
  const [questions, setQuestions] = useState(data);
  const [showMobilePopup, setShowMobilePopup] = useState(false);

  const handleQuestionClick = (id) => {
    setQuestions((prevQuestions) => {
      return prevQuestions.map((question) => {
        if (question.id === id) {
          //Si la pregunta coincide con el ID clickeado cambiamos el estado a "expanded"
          return {
            ...question,
            expanded: !question.expanded,
          };
        } else {
          return {
            ...question,
            expanded: false, //Cerramos las otras preguntas para que no se salgan del contenedor
          };
        }
      });
    });
  };

  useEffect(() => {
    const closePopup = () => {
      setShowMobilePopup(false);
    };

    document.addEventListener("click", closePopup);

    return () => {
      document.removeEventListener("click", closePopup);
    };
  }, []);

  return (
    <main>
      <div className="faq-background">
        <div className="faq-container">
          {questions.map((question) => (
            <SingleQuestion
              key={question.id}
              {...question}
              onQuestionClick={handleQuestionClick}
            />
          ))}
          
            <p className="contact-container-faq">
              Si tenés otra consulta, podes contactarnos a través de nuestros
              medios disponibles
            </p>
          
          <div className="contact-icons-faq">
            <div className="icons-container-faq">
            <div
                className="mbl"
                onClick={() => setShowMobilePopup(!showMobilePopup)}
              >
                <FontAwesomeIcon icon={faMobileScreen} size="lg" />
                <div className="popup" onClick={(e) => e.stopPropagation()}>
              
                  <p>03464423605</p>
                </div>
              </div>
              <a
                className="icons-faq"
                href="https://wa.me/+5493464500623"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faWhatsapp} className="wp-faq" />
              </a>
              <a
                className="icons-faq"
                href="https://www.facebook.com/caceresmaq"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faFacebook} size="lg" />
              </a>
              <a
                className="icons-faq"
                href="https://www.instagram.com/caceresmaquinarias/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  icon={faInstagram}
                  size="lg"
                  className="instagram-faq"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Accordion;
