import React from "react";
import "../styles/main.css";
import { Link } from "react-router-dom";

const Featured = () => {

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="grid-wrapper">
        <h2 className="featured-h2">Nuestros destacados</h2>
        <div className="grid-layout">
          <div className="box">
            <h3>
              <Link
                className="title-featured"
                to="/productos"
                onClick={scrollToTop}
              >
                Motosierras
              </Link>
            </h3>
            <p className="p-featured">
              Sentí el poder de corte de nuestras motosierras de primera línea.
              Nuestros productos te ofrecen rendimiento y precisión para
              enfrentar cualquier desafío con eficiencia.
            </p>
          </div>
          <div className="box">
            <h3>
              <Link
                className="title-featured"
                to="/productos"
                onClick={scrollToTop}
              >
                Cortadoras de cesped
              </Link>
            </h3>
            <p className="p-featured">
              Mantené tu césped impecable con nuestras cortadoras de césped de
              calidad. Elegi entre opciones a bateria o a nafta, y disfruta de
              un corte eficiente y uniforme.
            </p>
          </div>
          <div className="box">
            <h3>
              <Link
                className="title-featured"
                to="/productos"
                onClick={scrollToTop}
              >
                Cortacercos
              </Link>
            </h3>
            <p className="p-featured">
              Dale forma a tus cercos y arbustos con facilidad. Obtené
              resultados precisos y satisfactorios en tus labores de poda con
              herramientas diseñadas para facilitar tu trabajo.
            </p>
          </div>
          <div className="box">
            <h3>
              <Link
                className="title-featured"
                to="/productos"
                onClick={scrollToTop}
              >
                Sopladores
              </Link>
            </h3>
            <p className="p-featured">
              Facilitá tus tareas de limpieza con nuestros sopladores de mano.
              Elimina eficientemente hojas y desechos de tu jardín con
              herramientas que ofrecen comodidad y eficiencia en su uso.
            </p>
          </div>
          <div className="box">
            <h3>
              <Link
                className="title-featured"
                to="/productos"
                onClick={scrollToTop}
              >
                Motoguadañas y Bordeadoras
              </Link>
            </h3>
            <p className="p-featured">
              Sumergite en el mundo de las motoguadañas y bordeadoras, donde se
              unen la potencia y la precisión para transformar tus tareas de
              jardinería. Logra un perfecto corte en áreas difíciles con
              nuestras motoguadañas y bordeadoras y mantené tu jardín impecable.
            </p>
          </div>
          <div className="box">
            <h3>
              <Link
                className="title-featured"
                to="/productos"
                onClick={scrollToTop}
              >
                Tromen
              </Link>
            </h3>
            <p className="p-featured">
              Desde parrillas y hornos a leña hasta estufas y hogares, ofrecemos
              productos de alta calidad diseñados para brindarte resultados
              excepcionales y una experiencia única.
            </p>
          </div>
        </div>
        <div class="catalogue-btn-container">
          <Link to="/productos" class="catalogue-btn" onClick={scrollToTop}>
            Productos
          </Link>
        </div>
        <p className="catalogue">Explorá nuestro catálogo completo</p>
      </div>
    </>
  );
};

export default Featured;
