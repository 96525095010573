import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const ApiURL = "https://www.caceresmaquinarias.com.ar/api";

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const ProductsList = ({ categoryFilter, brandFilter, searchTerm }) => {
  const [products, setProducts] = useState([]);
  const [visibleProducts, setVisibleProducts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [hasMatches, setHasMatches] = useState(true);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        setLoading(true);
        let apiUrl = `${ApiURL}/productos`;

        const queryParams = [];

        if (categoryFilter.length > 0) {
          queryParams.push(`category=${categoryFilter.join(",")}`);
        }
        if (brandFilter.length > 0) {
          queryParams.push(`brand=${brandFilter.join(",")}`);
        }

        if (searchTerm) {
          queryParams.push(`search=${searchTerm}`);
        }

        if (queryParams.length > 0) {
          apiUrl += `?${queryParams.join("&")}`;
        }

        const response = await axios.get(apiUrl);

        setProducts(response.data);
	setHasMatches(response.data.length > 0);
      } catch (error) {
        console.log("Error obteniendo los productos:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProducts();
  }, [categoryFilter, brandFilter, searchTerm]);

  const productsPerPage = 40;

  useEffect(() => {
    const startIndex = (page - 1) * productsPerPage;
    const endIndex = startIndex + productsPerPage;
    const newVisibleProducts = products.slice(0, endIndex);
    setVisibleProducts(newVisibleProducts);
  }, [products, page]);

  const loadMoreProducts = () => {
    setPage((prevPage) => prevPage + 1);
  };

  return (
    <div className="products-container">
      {loading ? (
        <div className="loading-spinner-container">
          <div className="loading-spinner"></div>
        </div>
      ) : (
        <>
          {hasMatches ? (
            visibleProducts.map((product) => (
              <div key={product.slug} className="product-card">
                <img
                  src={product.image}
                  alt={product.name}
                  className="product-image"
                />
                <h3 className="product-name">{product.name}</h3>
                <Link
                  to={`/${product.slug}`}
                  className="details-product-button"
                  onClick={scrollToTop}
                >
                  Ver producto
                </Link>
              </div>
            ))
          ) : (
            <p className="filters-msg">No se encontraron productos</p>
          )}
          {visibleProducts.length < products.length && (
            <button className="load-more-button" onClick={loadMoreProducts}>
              Ver más productos
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default ProductsList;
