import React from "react";
import Slider from "react-slick";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import pic1 from "../images/carousel/1-motor.jpg";
import pic2 from "../images/carousel/2-concesionarios.jpg";
import pic3 from "../images/carousel/3-bateria.jpg";
import pic4 from "../images/carousel/4-poda.jpg";
import pic5 from "../images/carousel/5-hidro.jpg";
import pic6 from "../images/carousel/6-cadena.jpg";
import pic7 from "../images/carousel/7-hilo.jpg";
import pic8 from "../images/carousel/8-oidos.jpg";
import pic9 from "../images/carousel/9-valvula.jpg";

const CustomPrevArrow = (props) => (
  <button {...props} className="custom-prev-arrow">
    <FontAwesomeIcon icon={faChevronLeft} size="1x" color="#fff" />
  </button>
);

const CustomNextArrow = (props) => (
  <button {...props} className="custom-next-arrow">
    <FontAwesomeIcon icon={faChevronRight} size="1x" color="#fff" />
  </button>
);

const Carousel = () => {
  let settings = {
    arrows: true,
    dots: false,
    infinite: true,
    speed: 700,
    accessibility: false,
    slidesToShow: 3,
    slidesToScroll: 3,
    useTransform: false,
    initialSlide: 0,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 993,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className="carousel-container">
      <div className="tips-stihl">STIHL TIPS</div>
      <div className="carousel-wrapper">
        <Slider {...settings}>
          <div className="slide">
            <img
              alt="Pro tip: calentamiento del motor"
              src={pic1}
              className="slider-img"
            />
            <div className="overlay">
              <div className="carousel-hover-text">
                Después de encender la máquina y liberar el freno de cadena,
                debes realizar un calentamiento con pequeños golpes de
                aceleración. Esto permite purgar todo el aire que pudo haber
                quedado dentro del carburador y permite lubricar la espada y
                cadena. El calentamiento permite al pistón absorber calor y
                alcanzar la temperatura de operación correcta. Siempre hay que
                tener presente que no es correcto acelerar por más de 10
                segundos la motosierra sin ninguna carga, sin importar si es
                nueva o no.{" "}
              </div>
            </div>
          </div>
          <div className="slide">
            <img
              alt="Pro tip: concesionario oficial"
              src={pic2}
              className="slider-img"
            />
            <div className="overlay">
              <div className="carousel-hover-text">
                Al comprar en un concesionario STIHL oficial te estás llevando
                muchas cosas además de una tremenda herramienta: la seguridad de
                que estás haciendo una compra oficial, con garantía y atención
                post-venta aseguradas. Antes de comprar, siempre chequeá
                en&nbsp;
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://www.stihl.com.ar/concesionarios"
                  className="stihl-link"
                >
                  https://www.stihl.com.ar/concesionarios
                </a>{" "}
                que se trate de un concesionario oficial.
              </div>
            </div>
          </div>
          <div className="slide">
            <img
              alt="Pro tip: bateria en condiciones"
              src={pic3}
              className="slider-img"
            />
            <div className="overlay">
              <div className="carousel-hover-text">
                Si vas a guardar tu batería por un buen rato, es importante que
                esta cuente por lo menos con 2 líneas de carga, de esa forma le
                darás mayor vida útil a la batería durante el periodo de
                almacenamiento.
              </div>
            </div>
          </div>
          <div className="slide">
            <img
              alt="Pro tip: utilidad equipos bateria"
              src={pic4}
              className="slider-img"
            />
            <div className="overlay">
              <div className="carousel-hover-text">
                {" "}
                En zonas urbanas, la poda de árboles no sólo ayuda a mantener
                las veredas y jardines más lindos, también es fundamental para
                la seguridad de todos. Las ramas podridas, enfermas o muertas
                pueden alojar enfermedades. Ademas son peligrosas ya que pueden
                desprenderse solas. <br></br>
                Los arboles que dan a la calle se deben podar para evitar
                accidentes o destruccion de vehiculos en caso de tormenta.
                Recordá que, para hacerlo de forma correcta, es fundamental
                dejarlo en manos de profesionales.
              </div>
            </div>
          </div>
          <div className="slide">
            <img
              alt="Pro tip: hidrolavadora"
              src={pic5}
              className="slider-img"
            />
            <div className="overlay">
              <div className="carousel-hover-text">
                Antes de empezar a trabajar, abrí la llave de agua y con el
                interruptor apagado, accioná el gatillo de la pistola. Debe
                salir un chorro constante de agua. Con esto eliminaras todo el
                aire dentro de la bomba y facilitaras el encendido inicial de la
                hidrolavadora. Al terminar de trabajar, cerrá la llave de agua y
                con el interruptor apagado accioná el gatillo de la pistola.
                Debe salir un chorro constante de agua hasta terminarse. Con
                esto se elimina el agua dentro de la bomba y evitás la formación
                de sedimentos en tu equipo, sobre todo cuando dejes tu equipo
                inactivo por periodos medianos o largos.
              </div>
            </div>
          </div>
          <div className="slide">
            <img
              alt="Pro tip: destensa la cadena"
              src={pic6}
              className="slider-img"
            />
            <div className="overlay">
              <div className="carousel-hover-text">
                Cuando termines la jornada de trabajo con tu motosierra, te
                recomendamos destensar la cadena y desmontar la barra. Hacer
                esto facilita la limpieza de la máquina y ayuda a prolongar la
                vida de la cadena.
              </div>
            </div>
          </div>
          <div className="slide">
            <img
              alt="Pro tip: hidrata el hilo nylon"
              src={pic7}
              className="slider-img"
            />
            <div className="overlay">
              <div className="carousel-hover-text">
                Es importante que, una noche antes de iniciar tu jornada de
                trabajo con cualquiera de nuestras motoguadañas STIHL, metas la
                cantidad de hilo nylon que vayas a utilizar en una cubeta con
                agua y lo dejes reposar toda la noche. Ese hilo, ya que es un
                polímero, absorberá líquidos y hará que se vuelva más resistente
                y elástico. Durará mucho más tiempo trabajando sin que lo tengas
                que estar reemplazando por tiempos muy cortos debido a que se
                reseca.
              </div>
            </div>
          </div>
          <div className="slide">
            <img
              alt="STIHL cuida tus oidos"
              src={pic8}
              className="slider-img"
            />
            <div className="overlay">
              <div className="carousel-hover-text">
                Se recomienda el uso de protección auditiva a partir de solo 80
                dB para proporcionar la mejor proteccion posible para tus oídos.
                Por eso, STIHL te cuida ofreciendote diferentes opciones.{" "}
                <br></br>
                Tapones para los oidos: ideales para períodos mas largos y con
                mayor comodidad <br></br>
                Cascos de protección auditiva: son adecuados para todo tipo de
                trabajos, tanto en interiores como exteriores. <br></br>
                Si estas expuesto a niveles de ruido extremadamente altos, podés
                usar tapones y cascos de protección para los oídos al mismo
                tiempo.
              </div>
            </div>
          </div>
          <div className="slide">
            <img
              alt="Pro tip: valvula ULV en las SR"
              src={pic9}
              className="slider-img"
            />
            <div className="overlay">
              <div className="carousel-hover-text">
                La válvula ULV (color gris) es un accesorio que se vende por
                separado para todas las fumigadoras de motor. Reduce el tamaño
                de la gota, y esto permite una mejor adherencia del activo en
                las hojas. Tambien, al reducir su caudal, extiende la duración
                del tanque de activo. La válvula ULV tiene un caudal de 0.04
                litros por minuto en su posición de menor flujo (SR 430,
                posición del tubo a 0°). Es decir, el tanque podrá durar hasta
                2.75 veces más con la válvula ULV. Una válvula estándar (color
                naranja) tiene un caudal de 0.11 litros por minuto en su
                posición de menor flujo (SR 430, posición del tubo a 0°).
              </div>
            </div>
          </div>
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;
