import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link, useParams, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesLeft } from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebook,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ApiURL = "https://www.caceresmaquinarias.com.ar/api";

const shuffleArray = (array) => {
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]];
  }
  return array;
};

const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};

const scrollToSpecifications = () => {
  const specifications = document.getElementById("product-specifications");

  if (specifications) {
    specifications.scrollIntoView({ behavior: "smooth" });
  }
};

const scrollToContactForm = () => {
  const contactForm = document.getElementById("contact-form");

  if (contactForm) {
    contactForm.scrollIntoView({ behavior: "smooth" });
  }
};

const SingleProduct = () => {
  const navigate = useNavigate();
  const { productSlug } = useParams();
  const [product, setProduct] = useState(null);
  const [relatedProducts, setRelatedProducts] = useState([]);
  const [productName, setProductName] = useState("");

  const sendFormEmail = async (event) => {
    event.preventDefault();

    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const message = document.getElementById("message").value;

    try {
      const response = await axios.post(`${ApiURL}/email`, {
        name,
        email,
        message,
        productName,
      });

      if (response.data.success) {
        toast.success(
          "Consulta enviada con éxito. Te responderemos a la brevedad!",
          {
            position: "top-right",
            autoClose: 4500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }

      document.getElementById("name").value = "";
      document.getElementById("email").value = "";
      document.getElementById("message").value = "";
    } catch (error) {
      console.error("Error al enviar el correo electrónico:", error);
      toast.error(
        "Error al enviar el correo electrónico. Intentá de nuevo más tarde",
        {
          position: "top-right",
          autoClose: 4500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  useEffect(() => {
    const fetchProduct = async () => {
      try {
        const response = await axios.get(`${ApiURL}/productos/${productSlug}`);
        setProduct(response.data);
        setProductName(response.data.name);
      } catch (error) {
        console.error("Error obteniendo el producto:", error);
      }
    };

    fetchProduct();
  }, [productSlug]);

  useEffect(() => {
    const fetchRelatedProducts = async () => {
      try {
        if (product && product.category && product.brand) {
          const response = await axios.get(
            `${ApiURL}/productos?category=${product.category}&brand=${product.brand}`
          );

          const filteredProducts = response.data.filter(
            (relatedProduct) => relatedProduct.slug !== productSlug
          );

          if (filteredProducts.length > 0) {
            const shuffledProducts = shuffleArray(filteredProducts);
            const limitedRelatedProducts = shuffledProducts.slice(0, 4);

            setRelatedProducts(limitedRelatedProducts);
          } else {
            const brandProductsResponse = await axios.get(
              `${ApiURL}/productos?brand=${product.brand}`
            );

            const brandProducts = brandProductsResponse.data.filter(
              (brandProduct) => brandProduct.slug !== productSlug
            );

            const shuffledBrandProducts = shuffleArray(brandProducts);
            const limitedBrandProducts = shuffledBrandProducts.slice(0, 4);

            setRelatedProducts(limitedBrandProducts);
          }
        }
      } catch (error) {
        console.error("Error obteniendo productos relacionados:", error);
      }
    };

    if (product) {
      fetchRelatedProducts();
    }
  }, [product, productSlug]);

  return (
    <div className="product-general-background">
      <div className="product-container">
        {product && (
          <div className="product-details-wrapper">
            <div className="return-link-container">
              <Link to="/" className="return-link" onClick={() => navigate(-1)}>
                <FontAwesomeIcon className="return-icon" icon={faAnglesLeft} />
                {"Volver"}
              </Link>
            </div>
            <img
              src={product.image}
              alt={product.name}
              className="product-image-detail"
            />
            <div className="product-info">
              <h1 className="product-name-detail">{product.name}</h1>
              <p className="product-description-detail">
                {product.description}
              </p>
              <div onClick={scrollToSpecifications} className="spec-product">
                Ver descripcion completa y especificaciones
              </div>

              <button
                className="details-single-product-button"
                onClick={scrollToContactForm}
              >
                Consultar sobre este u otro producto
              </button>
              <p className="img-disclaimer">
                Las imágenes son a modo ilustrativo. Consultá a nuestro personal
                la inclusión de accesorios, stock y por asesoramiento
                personalizado.
              </p>
            </div>
          </div>
        )}
      </div>
      {product && (
        <div className="product-specification-container" id="product-specifications">
          <div className="product-description-wrapper">
            <h2 className="full-description">Descripción</h2>
            {product.fullDescription}
          </div>
          <div className="product-specification-wrapper">
            <h2 className="product-specification">Especificación</h2>
            <table className="product-spec-tb">
              <tbody>
                {Object.entries(product.specifications).map(
                  ([spec, value], index) => (
                    <tr
                      key={spec}
                      className={index % 2 === 0 ? "gray-row" : ""}
                    >
                      <td className="spec-name">{spec}</td>
                      <td className="spec-value">{value}</td>
                    </tr>
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      )}
      {
        <div>
          {product && (
            <div className="related-products-container">
              <div className="related-products-wrapper">
                <h2 className="related-products-title">
                  Productos Relacionados
                </h2>
                <div className="related-products-cards">
                  {relatedProducts.map((relatedProduct) => (
                    <div
                      key={relatedProduct.slug}
                      className="related-products-card responsive-card"
                    >
                      <img
                        src={relatedProduct.image}
                        alt={relatedProduct.name}
                        className="product-image responsive-card-img"
                      />
                      <h3 className="product-name responsive-card-name">{relatedProduct.name}</h3>
                      <Link
                        to={`/${relatedProduct.slug}`}
                        className="details-product-button responsive-card-btn"
                        onClick={scrollToTop}
                      >
                        Ver producto
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
      }
      <div className="contact-product-container" id="contact-form">
        <div className="contact-product-wrapper">
          <h2 className="contact-title">Contacto</h2>
          <h3 className="contact-subtitle">
            Dejanos tu consulta y te responderemos vía mail lo antes posible
          </h3>
          <form className="contact-form" onSubmit={sendFormEmail}>
            <label className="contact-label" htmlFor="name">
              Nombre
            </label>
            <input
              className="contact-input"
              type="text"
              id="name"
              name="name"
              placeholder="Ingresá tu nombre"
              required
            />

            <label className="contact-label" htmlFor="email">
              Correo electrónico
            </label>
            <input
              className="contact-input"
              type="email"
              id="email"
              name="email"
              placeholder="Ingresá un mail válido"
              required
            />

            <label className="contact-label" htmlFor="message">
              Consulta
            </label>
            <textarea
              className="contact-textarea"
              id="message"
              name="message"
              rows="4"
              placeholder="Dejá tu consulta acá"
              required
            />

            <label className="contact-label" htmlFor="productName">
              Producto
            </label>
            <input
              className="contact-input"
              type="text"
              id="productName"
              name="productName"
              value={productName}
              readOnly
              disabled
            />

            <button type="submit" className="contact-submit-button">
              Enviar mensaje
            </button>
            <ul className="social-contact-form">
              <li>
                <a
                  href="https://www.facebook.com/caceresmaq"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faFacebook} className="fb-icon" />
                </a>
                <a
                  href="https://www.instagram.com/caceresmaquinarias/"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faInstagram} className="ig-icon" />
                </a>
                <a
                  href="https://wa.me/+5493464500623"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faWhatsapp} className="wp-icon" />
                </a>
              </li>
              <li>
                <div>También podés hacer tu consulta por nuestras redes</div>
              </li>
            </ul>
          </form>
          <ToastContainer />
        </div>
      </div>
    </div>
  );
};

export default SingleProduct;
