import React from "react";
import "../styles/main.css";
import hidro from "../images/hidrolavadora-stihl.jpg";
import ms from "../images/ms-stihl.jpg";
import cortacercos from "../images/cortacercos-stihl.jpg";
import bateria from "../images/bateria-stihl.jpg";
import soplador from "../images/soplador-stihl.jpg";
import tromen from '../images/tromen.jpg';

const Images = () => {
  return (
    <>
      <div className="grid-container">
        <div className="wrapper-imgs">
          <div class="grid-item">
            <img
              src={hidro}
              alt="STIHL-hidro"
              className="image-first-column"
            ></img>
            <img src={ms} alt="STIHL" className="image-first-column"></img>
            <img
              src={cortacercos}
              alt="STIHL"
              className="image-first-column"
            ></img>
          </div>
          <div class="grid-item">
            <img
              src={bateria}
              alt="STIHL"
              className="image-second-column"
            ></img>
            <img
              src={soplador}
              alt="STIHL"
              className="image-second-column"
            ></img>
            <img src={tromen} alt="STIHL" className="image-second-column"></img>
          </div>
        </div>
      </div>
    </>
  );
};

export default Images;
