const AccordionData = [
  {
    id: 1,
    title: "¿Cuáles son los horarios de atención?",
    info: "Lunes a viernes de 8 a 12hs y de 15 a 19hs. Sábados por la mañana de 8 a 12hs",
  },
  {
    id: 2,
    title: "¿Todos los productos tienen garantía?",
    info: "Todos los productos que comercializamos poseen garantía de fabrica respaldada por el proveedor. Los plazos de cada una depende del producto.",
  },
  {
    id: 3,
    title: "¿Qué hago si tengo un problema con mi producto en garantía?",
    info: "En principio debe dirigirse a cualquier concesionario oficial STIHL. Allí deberá presentar el producto junto con el certificado de garantía debidamente completo y sellado por el distribuidor que realizare la venta del implemento, junto con su factura de venta original.",
  },
  {
    id: 4,
    title: "¿Cuándo debo afilar o cambiar la cadena de mí motosierra?",
    info: "Algunos indicadores de que la cadena está desafilada son si genera aserrín y no viruta, si el corte no es preciso, o si se desvía o rebota al cortar. En estos casos es necesario afilar. Luego de varios afilados, nuestros técnicos le pueden indicar si es necesario cambiarla.",
  },
  {
    id: 5,
    title: "¿Cómo elijo mí estufa a leña?",
    info: "Las estufas a leña tienen una capacidad calorífica según el espacio de su caja de fuegos (dónde va la leña). Lo principal es conocer la superficie a calefaccionar para saber que modelo elegir. Nuestros asesores lo guiarán en su elección.",
  },
];
export default AccordionData;
