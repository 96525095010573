import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar";
import Background from "./components/Background";
import Logos from "./components/Logos";
import Featured from "./components/Featured";
import Images from "./components/Images";
import Carousel from "./components/Carousel";
import Footer from "./components/Footer";
import Accordion from "./components/Accordion";
import FiltersProducts from "./components/FiltersProducts";
import SingleProduct from "./components/Product";

const App = () => {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route exact path="/" element={<HomePage />} />
        <Route path="/preguntas-frecuentes" element={<Accordion />} />
        <Route path="/productos/*" element={<FiltersProducts />} />
        <Route path="/:productSlug" element={<SingleProduct />} />
      </Routes>
      <Footer />
    </Router>
  );
};

const HomePage = () => {
  return (
    <>
      <Background />
      <Logos />
      <Featured />
      <Images />
      <Carousel />
    </>
  );
};

export default App;
