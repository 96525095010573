import React from "react";
import "../styles/main.css";

import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationDot,
  faSquarePhone,
} from "@fortawesome/free-solid-svg-icons";
import {
  faInstagram,
  faFacebook,
  faWhatsapp,
} from "@fortawesome/free-brands-svg-icons";
import "font-awesome/css/font-awesome.min.css";

import logo from "../images/Logo-CM.png";

const Footer = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <>
      <div className="footer" id="contacto">
        <div className="logo-footer">
          <img src={logo} alt="Logo negocio" />
        </div>
        <ul className="links-footer">
          <li>
            <Link to="/" onClick={scrollToTop}>
              Inicio
            </Link>
          </li>
          <li>
            <Link to="/productos" onClick={scrollToTop}>
              Productos
            </Link>
          </li>
          <li>
            <Link to="/preguntas-frecuentes" onClick={scrollToTop}>
              Preguntas frecuentes
            </Link>
          </li>
        </ul>
        <div className="line-through"></div>
        <div className="contact-container">
          <div className="icons-container">
            <div>
              <FontAwesomeIcon icon={faLocationDot} className="icon-contact" />
            </div>
            <div className="text-icons">
              Blvd. Ovidio Lagos 2160,
              <br />
              S2170EYY Casilda, Santa Fe
            </div>
          </div>
          <div className="icons-container">
            <div>
              <FontAwesomeIcon icon={faSquarePhone} className="icon-contact" />
            </div>
            <div className="text-number">+54 9 03464 423605</div>
          </div>
        </div>
        <div className="social-network">
          <div>
            <a
              href="https://www.facebook.com/caceresmaq"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={faFacebook} className="social-icons" />
            </a>
          </div>
          <div>
            <a
              href="https://www.instagram.com/caceresmaquinarias/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faInstagram}
                className="social-icons instagram"
              />
            </a>
          </div>
          <div>
            <a
              href="https://wa.me/+5493464500623"
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon
                icon={faWhatsapp}
                className="social-icons whatsapp"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
